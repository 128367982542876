/*! Manage main.js
 * ================
 * Main JS application file for TribePad Manage
 *
 * @Author  Dan Dunford
 * @Email   <support@tribepad.com>
 * @version 1.0
 */

'use strict';

//Make sure jQuery has been loaded before app.js
if (typeof jQuery === "undefined") {
    throw new Error("TribePad Manage requires jQuery");
}

$(function(){
    //Setup ajax to always include the CSRF token
    $.ajaxSetup({
        headers: { 'X-CSRF-Token' : $('meta[name=_token]').attr('content') }
    });

    $.fn.bootstrapSwitch.defaults.size = 'small';
    $.fn.bootstrapSwitch.defaults.onColor = 'success';
    $(".bs-onoff").bootstrapSwitch();

    $.fn.bootstrapSwitch.defaults.onText = 'Yes';
    $.fn.bootstrapSwitch.defaults.offText = 'No';
    $(".bs-yesno").bootstrapSwitch();

    _userAdmin(jQuery);
});

function _userAdmin(jQuery) {

    if ($('#search_roleId').length > 0) {
        if ($('select#search_roleClientId option').length === 0) {
            $('#search_roleClientId').append('<option value="">Sub role</option>').prop('disabled', true);
        }

        $(document).on('change', '#search_roleId', function () {
            _updateSearchSubRoles(this.value, $(this).data('srurl'));
        });

        if ($('#search_roleId option:selected').val() > 0) {
            _updateSearchSubRoles(
                $('#search_roleId option:selected').val(),
                $('#search_roleId').data('srurl'),
                $('#search_roleClientId').data('role-client-id')
            );
        }
    }

    if ($('#userType').length > 0) {
        if ($('select#subUserType option').length <= 0) {
            $('#subUserType').prop('disabled', true);
        }

        $(document).on('change', '#userType', function () {
            $('#companyName').parent().parent().hide();
            $('#hierarchyField').show();

            if (this.value > 1) {
                $('#default_hierarchy_wrapper').show();
                $('#default_business_units_wrapper').show();
                $('#default_regions_wrapper').show();
            } else {
                $('#default_hierarchy_wrapper').hide();
                $('#default_business_units_wrapper').hide();
                $('#default_regions_wrapper').hide();
            }

            _updateSubRoles(this.value, $(this).data('srurl'));
        });

        $(document).on('change', '#subUserType', function () {
            if($('#companyName').length > 0) {
                $('#companyName').parent().parent().hide();
                if (this.value == 5) {
                    $('#companyName').parent().parent().show();
                }
            }

            if($('#hierarchyField').length > 0) {
                $('#hierarchyField').show();
                if($('#userType').val() == 2 && $('#subUserType').val() == 5) {
                    $('#hierarchyField').hide();
                }
            }
        });
    }

    if ($('#brandCode').length > 0) {
        $('#brandCode').parent().parent().hide();
    }

    if ($('#hierarchyField').length > 0) {
        $('#hierarchyField').show();

        if ($('#userType').val() == 2 && $('#subUserType').val() == 5) {
            $('#hierarchyField').hide();
        }
    }

    if ($('#companyName').length > 0) {
        $('#companyName').parent().parent().hide();
        if ($('#userType').val() == 2 && $('#subUserType').val() == 5) {
            $('#companyName').parent().parent().show();
        }
    }

    if ($('#default_hierarchy_wrapper').length > 0) {
        $('#default_hierarchy_wrapper').hide();
        if ($('#userType').val() > 1) {
            $('#default_hierarchy_wrapper').show();
        }
    }

    if ($('#default_business_units_wrapper').length > 0) {
        $('#default_business_units_wrapper').hide();
        if ($('#userType').val() > 1) {
            $('#default_business_units_wrapper').show();
        }
    }

    if ($('#default_regions_wrapper').length > 0) {
        $('#default_regions_wrapper').hide();
        if ($('#userType').val() > 1) {
            $('#default_regions_wrapper').show();
        }
    }

    if ($('.user-edit').length > 0) {
        $('.user-edit').click(function() {
            $.ajax({
                url: $(this).attr('href'),
                type: "GET",
                cache: false,
                dataType: 'html',
                success: function(data) { _initUserUpdateForm(data); },
                error: handleError
            });

            return false;
        });
    }

    if ($('#user_search').submit(function(event) {
            $('#search-results').html('');
            $('#top-left-logo').addClass('loading');
        })
    );

    $(document).on('click', '.referral-delete', function () {
        var endpoint = $(this).data('endpoint');
        var referralId = $(this).data('referral-id');

        sendGetRequest(endpoint, { referralId : referralId }, function (data, response) {
            $('#referral-table-row-' + data.referralId).hide();
        });
    });

    _switchJobPosting('#user_panel');
}

function _updateSubRoles(roleId, subRoleUrl)
{
    if ($('#brandCode').length > 0) {
        $('#brandCode').parent().parent().hide();
        if (roleId.substr(0, 1) == 2) {
            $('#brandCode').parent().parent().show();
        }
    }

    $.ajax({
        url: subRoleUrl,
        data: {roleId: roleId},
        type: "GET",
        cache: false,
        dataType: 'json',
        success: function (data) {
            var toAppendNone = '<option value="">No sub roles</option>';
            var toAppend = '';

            $.each(data, function (index, value) {
                toAppend = toAppend + '<option value="' + index + '">' + value + '</option>';
            });

            var append = toAppendNone;
            $('#subUserType').prop('disabled', true);
            if (toAppend.length > 0) {
                append = toAppend;
                $('#subUserType').prop('disabled', false);
            }

            $('#subUserType')
                .find('option')
                .remove()
                .end()
                .append(append);

            if ($('#companyName').length > 0) {
                $('#companyName').parent().parent().hide();
                if($('#subUserType').val() == 5) {
                    $('#companyName').parent().parent().show();
                }
            }

            if ($('#hierarchyField').length > 0) {
                $('#hierarchyField').show();
                if($('#userType').val() == 2 && $('#subUserType').val() == 5) {
                    $('#hierarchyField').hide();
                }
            }
        },
        error: handleError
    });
}

function _updateSearchSubRoles(roleId, subRoleUrl, preselected)
{
    if (!roleId) {
        $('#search_roleClientId')
            .find('option')
            .remove()
            .end()
            .append('<option value="">Sub role</option>')
            .prop('disabled', true);
    }

    $.ajax({
        url: subRoleUrl,
        data: {roleId: roleId},
        type: "GET",
        cache: false,
        dataType: 'json',
        success: function (data) {
            var toAppendNone = '<option value="">No sub roles</option>';
            var toAppend = '<option value="">Sub role</option>';

            $.each(data, function (index, value) {
                toAppend = toAppend + '<option value="' + index + '">' + value + '</option>';
            });

            var append = toAppendNone;
            $('#search_roleClientId').prop('disabled', true);
            if (toAppend.length > 0) {
                append = toAppend;
                $('#search_roleClientId').prop('disabled', false);
            }

            $('#search_roleClientId')
                .find('option')
                .remove()
                .end()
                .append(append);

            if (preselected) {
                $('#search_roleClientId').val(preselected);
            }
        },
        error: handleError
    });
}

function _updateUserForm(form)
{
    $("#update_user_form").submit(function() {
        loaderHelper.create("#user_edit_panel");

        var action = $("#update_user_form").attr('action');
        var method = $("#update_user_form").attr('method');
        var data = $("#update_user_form").serialize();
        $.ajax({
            url: action,
            data: data,
            type: method,
            cache: false,
            success: function (data) {
                _initUserUpdateForm(data);

                $( '#form-errors' ).html('<div class="alert alert-success">User successfully updated</div>');
                loaderHelper.remove("#user_edit_panel");
            },
            error: handleError
        });

        return false;
    });
}

function _initUserUpdateForm(data)
{
    $( '#form-errors' ).html('');
    $('#user_panel').html(data);
    _updateUserForm($("#update_user_form", data));
    $("[name='options[]']").bootstrapSwitch();
    _updateSubRoles($('#userType').val(), $('#subUserType').data('srurl'));
    $(document).on('change', '#userType', function () {
        _updateSubRoles(this.value, $(this).data('srurl'));
    });

    if($('#companyName').length > 0) {
        $('#companyName').parent().parent().hide();
        if ($('#userType').val() == 2 && $('#subUserType').val() == 5) {
            $('#companyName').parent().parent().show();
        }
    }

    if ($('#default_hierarchy_wrapper').length > 0) {
        $('#default_hierarchy_wrapper').hide();
        if ($('#userType').val() > 1) {
            $('#default_hierarchy_wrapper').show();
        }
    }

    if ($('#default_business_units_wrapper').length > 0) {
        $('#default_business_units_wrapper').hide();
        if ($('#userType').val() > 1) {
            $('#default_business_units_wrapper').show();
        }
    }

    if ($('#default_regions_wrapper').length > 0) {
        $('#default_regions_wrapper').hide();
        if ($('#userType').val() > 1) {
            $('#default_regions_wrapper').show();
        }
    }

    if($('#hierarchyField').length > 0) {
        $('#hierarchyField').show();
        if($('#userType').val() == 2 && $('#subUserType').val() == 5) {
            $('#hierarchyField').hide();
        }
    }

    $.fn.bootstrapSwitch.defaults.size = 'small';
    $.fn.bootstrapSwitch.defaults.onColor = 'success';
    $(".bs-onoff").bootstrapSwitch();

    $.fn.bootstrapSwitch.defaults.onText = 'Yes';
    $.fn.bootstrapSwitch.defaults.offText = 'No';
    $(".bs-yesno").bootstrapSwitch();
    _switchJobPosting('#user_panel');
}

function _switchJobPosting(container)
{

    $($('.switch:selected', container).val()).removeClass('hide');

    $('.switch', container).click(function() {
        $('.switch_item').addClass('hide');
        $($(this).val()).removeClass('hide');
    });
}

function handleError(httpObj, textStatus)
{
    if (!httpObj) return false;

    if (httpObj.status==401) {
        location.reload();
    } else if ( httpObj.status === 422 ) {
        //clean up
        $('.hasError').removeClass('hasError');
        $('.help-block.form').remove();

        //process validation errors here.
        var errors = httpObj.responseJSON; //this will get the errors response data.
        //show them somewhere in the markup
        //e.g
        var errorsHtml = '<div class="alert alert-danger"><ul>';

        $.each( errors, function( key, value ) {
            //show overall errors
            errorsHtml += '<li>' + value[0] + '</li>';

            //now show the error on the input
            var input = $( "input[name='"+key+"']");
            $(input).parent().parent().addClass('has-error');
            $(input).after('<span class="help-block form">'+value[0]+'</span>');

            //and rebind the submit event
            //_updateUserForm($('#update_user_form'));
        });
        errorsHtml += '</ul></di>';

        $( '#form-errors' ).html( errorsHtml );
    } else {

    }

    loaderHelper.remove("#user_edit_panel");
}

function sendGetRequest(endpoint, data, callback) {
    notify('info', 'Processing...');
    $.get(endpoint, data)
        .done(function(response) {
            callback(data, response);
        })
        .fail(function(err) {
            console.log(err);

            if (err) {
                notify('danger', err);
            } else {
                notify('danger', 'Something went wrong. Please contact your account manager.');
            }
        });
}

// Notifications handler 
function notify(type, message) {
    $.notify({
        message: message
    },{
        type: type,
        animate: {
            enter: 'animated fadeInDown',
            exit: 'animated fadeOutUp'
        },
        delay: 2000
    });
}